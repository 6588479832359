body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  /* font-family: sans-serif, BlinkMacSystemFont, 'Segoe UI', 'Nunito', Arial, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol'; */
} 

h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  /* font-family: sans-serif, BlinkMacSystemFont, 'Segoe UI', 'Nunito', Arial, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol'; */
}
  
h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  /* font-family: sans-serif, BlinkMacSystemFont, 'Segoe UI', 'Nunito', Arial, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol'; */
}
